import React from 'react';
import { HeaderArea } from './styled';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';

const Header = () => {

    const exit = () => {

    }

    return(
        <HeaderArea>
            <div className='area'> {/* mudar para area e copiar o styled por completo */}
                <div className='logo'>
                    <Link to="/">
                        <img src='/img/logobg.png' className='logoImg' />
                    </Link>
                </div>

                <Navbar expand="lg" className="navbar">
                    
                    <div className='menu_mobile' >
                        <Navbar.Brand href="#" style={{color: '#FFF'}}>Menu</Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" style={{ color: '#FFF', backgroundColor: '#FFF'}} />
                    </div>
                    
                    <Navbar.Collapse id="navbarScroll">
                    
                        <div className="menu_web">
                        <Nav 
                            className="menu_texto"
                            navbarScroll> 
                            <Nav.Link href="/" className="titulo_menu">Home</Nav.Link>
                            <Nav.Link href="/Servicos" className="titulo_menu">Serviços</Nav.Link>
                            
                            <Nav.Link href="/Sobre" className="titulo_menu">Sobre</Nav.Link>
                            <Nav.Link href="/Localizacao" className="titulo_menu">Localização</Nav.Link>
                            {/*
                            <NavDropdown title="Link" id="navbarScrollingDropdown">
                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
                            </NavDropdown>
                            */}
                        </Nav> 
                        
                        </div>
                    </Navbar.Collapse>
                </Navbar>
                
                <div className='tel'>
                    <h5 className="titulo_menu_tel">{`(11) 2045-2929`}</h5>
                </div>

            </div>
        </HeaderArea>
    )
}
 
export default Header; 