import {useEffect , useState } from 'react';
import "./styles.css";
import {
    Container, 
    Row,
    Col,
    Carousel,
    Image,
    Card,
    Button,
    CardGroup,
  } from "react-bootstrap";

  import TextAnimado from '../../components/text_animate';

  import Whatsapp from '../../components/whatsapp';

const Sobre = () => {

  const Rolagem = () => {
      // Redireciona para o elemento com o ID "meuId" após o componente ser montado
      const elemento = document.getElementById('servico');
      if (elemento) {
        elemento.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
    useEffect(() => {
      Rolagem();
    }, []);
  
    return(
<>
      <Whatsapp />
        <div className="area">

<div className="banner_area">
          <div className="banner">
           
                {/* BANNER */}
                <Carousel>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/banner_1.jpg"
                      alt="Equipamentos de ponta"
                    />

                    <Carousel.Caption>
                      {/* <h3>Equipamentos de ponta</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/banner_2.jpg"
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      {/* <h3>Equipamentos de ponta</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>

                
                </Carousel>
              

              {/* BANNER FINAL*/}
            
          </div>
        </div>
        <span id="servico"></span>
      <div className="body_area">
        
      <div className="body_top">
            <div className="card mb-3" style={{ width: "100%", border: 'none', borderRight: '1px solid #DDDDDD' }}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="/img/banner_home_left.jpg"
                    style={{ maxHeight: "330px" }}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">Sobre nós</h5>

                    <p className="text_left" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                      Somos uma empresa a mais de 20 anos no mercado. Trabalhando
                      com a manutenção automotiva na área de elétrica e
                      eletrônica, possuímos equimentos de ponta, ferramentas de
                      reparação e mão de obra especializada para um diaginóstico
                      preciso e eficaz.
                    </p>
                    <p className="text_left" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                      Buscamos constantemente atingir as expectativas esperadas
                      por nossos clientes, executando todos os serviços com
                      qualidade, transparência e segurança.
                    </p>

                    <p className="text_left" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                      Isso inclui sempre trazer inovação e tecnologia para o
                      mercado em que estamos inseridos. Atribuímos constantes
                      atualizações dos nossos equipamentos, a todo momento
                      prezando por sempre ter o equipamento correto e atual para
                      atender a demanda do mercado.
                    </p>
                    <p className="card-text">
                      <small className="text-muted">
                        fabicar.com.br
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div_visao">
            <TextAnimado />
          </div>


        <div className="body_bottom">
          
        </div>
      </div>
    </div>

    </>
    )
}

export default Sobre;