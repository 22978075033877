import React from 'react';
import {useEffect , useState } from 'react';
import "./styles.css";
import {
    Container,
    Row,
    Col,
    Carousel,
    Image,
    Card,
    Button,
    CardGroup,
  } from "react-bootstrap";

  import Whatsapp from '../../components/whatsapp';

const Localizacao = () => {


   const Rolagem = () => {
        // Redireciona para o elemento com o ID "meuId" após o componente ser montado
        const elemento = document.getElementById('servico');
        if (elemento) {
          elemento.scrollIntoView({ behavior: 'smooth' });
        }
      }
    
      useEffect(() => {
        Rolagem();
      }, []);

    return(

      <>
      <Whatsapp />
        <div className="area">

          
                  <div className="banner_area">
                    <div className="banner">
                     
                          {/* BANNER */}
                          <Carousel>
          
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src="/img/banner_1.jpg"
                                alt="Equipamentos de ponta"
                              />
          
                              <Carousel.Caption>
                                {/* <h3>Equipamentos de ponta</h3> */}
                              </Carousel.Caption>
                            </Carousel.Item>
          
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src="/img/banner_2.jpg"
                                alt="Second slide"
                              />
          
                              <Carousel.Caption>
                                {/* <h3>Equipamentos de ponta</h3> */}
                              </Carousel.Caption>
                            </Carousel.Item>
          
                          
                          </Carousel>
                        
          
                        {/* BANNER FINAL*/}
                      
                    </div>
                  </div>
                  <span id="servico"></span>

      <div className="body_area">
        <div className="body_top">
          <div className="card mb-3" style={{ width: "100%" }}>
            <div className="row g-0">
              <div className="col-md-4">
                <img
                  src="/img/localizacao.gif"
                  style={{ maxHeight: "330px" }}
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Localização</h5>

                  <p className="card-text">
                  Encontrar a localização ideal para uma oficina é crucial para o sucesso do negócio. 
                  A escolha do local certo pode impactar diretamente na visibilidade, 
                  acessibilidade e conveniência para os clientes.
                  </p>
                  <p className="card-text">
                  Uma oficina estrategicamente localizada em uma área de grande circulação de veículos 
                  ou próximo a regiões residenciais.
                  </p>

                  <p className="card-text">
                  Além disso, a proximidade de fornecedores e serviços essenciais, como lojas de autopeças, 
                  pode otimizar o funcionamento interno da oficina.
                  </p>
                  <p className="card-text">
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="body_bottom">
          
        </div>
      </div>
    </div>

    </>
    )
}

export default Localizacao;