import React from 'react';
//import ReactDOM from 'react-dom/client';
import { createStore } from "redux";
import { Provider } from "react-redux";

import Reducers from './Reducer';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

import { createRoot } from 'react-dom/client';
const store = createStore(Reducers);
const root = createRoot(document.getElementById('root')as HTMLElement) ;

root.render(
<Provider store={store}>
    <App />
</Provider>);


