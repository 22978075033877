import styled from 'styled-components';
 
export const FooterArea = styled.div`
    height:400px;
    background-color:#2b2727; 
    border-top:1px solid #CCC;
    color: #FFF;
 
    .area{
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
    }

    .area_div{
        flex:1;
        margin-top: 20px;
        text-align:left; 
    }

    .area_div2{
        flex:1;
        margin-top: 20px;
        text-align:left; 
    }

    .area_div3 {
        flex:2;
        margin-top: 20px;
        text-align:left; 
    }

    .texto_color {
        color:#fdce54;
    }

    .logoImg {
        width: 40%;
        text-align:center;
    }

    .div_footer_logo {
        width: 100%;
        display: ruby;
    }

    @media only screen and (max-width: 540px) { 
        height:950px;

        .area {
            flex-direction: column;
        }
    }
`;