import React, { useState, useEffect } from 'react';
import "./styles.css";
import {
    Container,
    Row,
    Col,
    Carousel,
    Image,
    Card,
    Button,
    CardGroup,
  } from "react-bootstrap";

  import Whatsapp from '../../components/whatsapp';

const Servicos = () => {

  const Rolagem = () => {
    // Redireciona para o elemento com o ID "meuId" após o componente ser montado
    const elemento = document.getElementById('servico');
    if (elemento) {
      elemento.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    Rolagem();
  }, []);



    return(
<>
      <Whatsapp />
        <div className="area">


          <div className="banner_area">
                    <div className="banner">
                     
                          {/* BANNER */}
                          <Carousel>
          
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src="/img/banner_1.jpg"
                                alt="Equipamentos de ponta"
                              />
          
                              <Carousel.Caption>
                                {/* <h3>Equipamentos de ponta</h3> */}
                              </Carousel.Caption>
                            </Carousel.Item>
          
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src="/img/banner_2.jpg"
                                alt="Second slide"
                              />
          
                              <Carousel.Caption>
                                {/* <h3>Equipamentos de ponta</h3> */}
                              </Carousel.Caption>
                            </Carousel.Item>
          
                          
                          </Carousel>
                        
          
                        {/* BANNER FINAL*/}
                      
                    </div>
                  </div>

      <span id="servico"></span>
      
      <div className="body_area">
      
      

      <hr className="featurette-divider"></hr>

<div className="container-fluid" style={{ backgroundColor: '#dddddd' }}>

<div className="row featurette mt-4 mb-4 m-auto" style={{ alignSelf: 'end'}}>
  <div className="col-sm-3 col-md-6 col-lg-8" style={{ backgroundColor: '#dddddd'  }}>
    <div className="overflow-hidden">
      <div className="row gy-4">
        <div>
        <h5 className="shadow_texte_2 mt-2">Nossos Serviços</h5>
        <h5 className="shadow_texte mt-2">Especialista em reparo, manutenção e programação módulos eletrônicos de carros  nacionais e importados.</h5>
        <h5 className="shadow_texte">Trabalhamos com diversos diagnósticos automotivos.</h5>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/injecao.png" className="icon" />Modulo Injeção Eletrônica </div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/bsi.png" className="icon" />Modulo Motor e Câmbio</div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/air_bag.png" className="icon" />AirBags</div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/abs.png" className="icon" />Freios ABS</div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/direcao.png" className="icon" />Direção Elétrica</div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/bsi.png" className="icon" />BSI / BSM </div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/imobilizador.png" className="icon" />Imobilizador </div>
        </div>
        <div className="col-6 back_text_serv">
          <div className="p-1 border bg-light"><img src="/img/bateria.png" className="icon" />Elétrica </div>
        </div>
        
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div style={{ justifyContent: 'right'}}>
      <img src="/img/banner_serv_3.jpg" style={{ maxHeight: '400px' }}/>
    </div>
    
  </div>
</div>

</div>

<hr className="featurette-divider"></hr>

        <div className="body_bottom">
          
            <div className="row row-cols-1 row-cols-md-3 g-4 mb-4">
              <div className="col">
                <div className="card">
                  <img
                    src="/img/diag_precisos.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Diagnóstico</h5>
                    <p className="card-text">
                    O diagnóstico veicular é uma etapa fundamental para manter a saúde e o bom 
                    funcionamento de um automóvel. Por meio de equipamentos específicos e da 
                    expertise técnica de profissionais qualificados, é possível identificar 
                    com precisão possíveis problemas e falhas nos sistemas do veículo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img
                    src="/img/equip_ponta.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Equipamentos</h5>
                    <p className="card-text">
                    Quando falamos em equipamento de ponta, estamos nos referindo a tecnologias 
                    de última geração que representam o que há de mais avançado em suas respectivas 
                    áreas de atuação. Estes equipamentos são caracterizados por sua alta precisão, 
                    eficiência e capacidade.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img
                    src="/img/mao_esp.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Mão de Obra</h5>
                    <p className="card-text">
                    A mão de obra especializada desempenha um papel crucial no funcionamento e 
                    sucesso de qualquer negócio. Profissionais com conhecimento técnico e 
                    habilidades específicas são essenciais para garantir a eficiência, 
                    qualidade e inovação em todas as etapas de um empreendimento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </div>
    </>
    )
}

export default Servicos;