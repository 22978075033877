import styled from 'styled-components';

export const HeaderArea = styled.div`
background-color:#2b2727;
height:120px;
border-bottom:1px solid #CCC;

.container {
    width: 100%;
    margin:auto;
    display:flex;
    height: 120px;
    position: fixed;
    z-index: 999;
    background-color:#2b2727;
}

.area {
    justfy-content: center;
    width: 100%;
    margin:auto;
    display:flex;
    flex-direction: row;
    height: 120px;
    position: fixed;
    z-index: 999;
    background-color:#2b2727;
}
 
 
a {
    text-decoration:none;
}

.logo {
    flex:1;
    display:flex;
    align-items:center;
    max-width: 200px;
}

.logoImg {
    width: 180px;
    height: 80px;
}

navbar {
   margin:auto;
   max-height: 100px;
}

nav {
    flex:1;
    background-color:#2b2727;
}

.menu_texto {
    justify-content: space-around;
}

.menu_web {
    width: 100%;
    max-width: 600px;
    flex-direction: row;
    max-height: 100px;
}

.tel{
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    max-width: 200px;
    max-height: 100%;
    
}
 
.titulo_menu {
    text-align: center;
    color:#fdce54;
    font-size: 20px;
    border: 1px solid #2b2727;
}

.titulo_menu_tel{
    padding-top: 10px;
    width: 100%;
    text-align: left;
    color:#fdce54;
    font-size: 20px;
    border: 1px solid #2b2727;
}

.titulo_menu:hover {
    background-color: #CCC;
    border: 1px solid #000;
    transition-duration: 0.5s;
    color:#000;
    border-radius: 10px;
}

.menu_mobile {
    color:#FFF;
    display: none;
    background-color:#2b2727;
}

@media only screen and (min-width:600px) and (max-width:990px) {
    .menu_mobile {
        display: block;
    }
}

@media only screen and (max-width:600px){

    .menu_mobile {
        display: block;   
    }

    .menu_texto {
    background-color:#2b2727;
    }
    
    .tel {
    display: none;
    }

}

`;